import type { SVGProps } from 'react';

export function All(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.75 15.2115H7.42857C6.64286 15.2115 6 14.5663 6 13.7778V7.43369C6 6.64516 6.64286 6 7.42857 6H13.75C14.5357 6 15.1786 6.64516 15.1786 7.43369V13.7778C15.1786 14.5663 14.5357 15.2115 13.75 15.2115ZM13.75 26H7.42857C6.64286 26 6 25.3548 6 24.5663V18.2222C6 17.4337 6.64286 16.7885 7.42857 16.7885H13.75C14.5357 16.7885 15.1786 17.4337 15.1786 18.2222V24.5663C15.1786 25.3548 14.5357 26 13.75 26ZM24.5714 26H18.25C17.4643 26 16.8214 25.3548 16.8214 24.5663V18.2222C16.8214 17.4337 17.4643 16.7885 18.25 16.7885H24.5714C25.3571 16.7885 26 17.4337 26 18.2222V24.5663C26 25.3548 25.3571 26 24.5714 26Z"
        fill="currentColor"
      />
      <path
        d="M17 10.5C17 11.6935 17.4741 12.8381 18.318 13.682C19.1619 14.5259 20.3065 15 21.5 15C22.6935 15 23.8381 14.5259 24.682 13.682C25.5259 12.8381 26 11.6935 26 10.5C26 9.30653 25.5259 8.16193 24.682 7.31802C23.8381 6.47411 22.6935 6 21.5 6C20.3065 6 19.1619 6.47411 18.318 7.31802C17.4741 8.16193 17 9.30653 17 10.5Z"
        fill="hsl(var(--nextui-primary)"
      />
    </svg>
  );
}
