import { All } from '@/assets/icons/comm/All';
import { Expenditure } from '@/assets/icons/comm/Expenditure';
import { Shell } from '@/assets/icons/comm/Shell';
import {
  CompletedOrderOrderStatusEnum,
  CompletedOrderOrderTypeEnum,
  FundsOrderHistoryEnum,
  FundsSourceEnum,
  WalletTypeEnum,
} from '@/enums/businessEnum';
import { useTranslation } from 'react-i18next';

export default () => {
  /*---------------------筛选状态--------------------------------*/
  //获取国际化函数
  const { t } = useTranslation();
  //审核中和已通过充值
  const statusOption = [
    {
      text: t('全部'),
      value: undefined,
    },
    {
      text: t('审核中'),
      value: CompletedOrderOrderStatusEnum.UnderReview,
    },
    {
      text: t('未通过'),
      value: CompletedOrderOrderStatusEnum.DID_NOT_PASS,
    },
    {
      text: t('已通过'),
      value: CompletedOrderOrderStatusEnum.COMPLETED,
    },
    {
      text: t('已取消'),
      value: CompletedOrderOrderStatusEnum.CANCELLED,
    },
  ];

  // 方向筛选
  const directionOption = [
    {
      text: t('全部'),
      value: undefined,
      Icon: All
    },
    {
      text: t('支出'),
      value: CompletedOrderOrderTypeEnum.BUY,
      Icon: Expenditure

    },
    {
      text: t('收入'),
      value: CompletedOrderOrderTypeEnum.SELL,
      Icon: Shell
    },
  ];

  // 资金流水来源筛选
  const screeningOfSourceOfCapitalOptions = [
    {
      text: t('全部'),
      value: undefined,
    },
    {
      text: t('充值'),
      value: 'RECHARGE',
    },
    {
      text: t('提现'),
      value: 'WITHDRAW',
    },
    {
      text: t('现货交易'),
      value: 'TRADE_SPOT',
    },
    {
      text: t('AI量化交易'),
      value: 'TRADE_AI_QUANT',
    },
    {
      text: t('申购IEO交易'),
      value: 'TRADE_IEO_SUBSCRIPTION',
    },
  ];


  // 合约资金流水来源筛选
  const contractSourceOption = [
    {
      text: t('全部'),
      value: undefined,
    },
    {
      text: t('购买'),
      value: 'TRADE_CONTRACT_BUY',
    },
    {
      text: t('交易结算'),
      value: 'TRADE_CONTRACT_SETTLE',
    },
    {
      text: t('交易资金费用'),
      value: 'TRADE_CONTRACT_FUNDING_FEES',
    },
    {
      text: t('交易爆仓清算'),
      value: 'TRADE_CONTRACT_LIQUIDATION',
    },
    {
      text: t('贷款'),
      value: 'LOAN',
    },
    {
      text: t('资产划转'),
      value: 'TRADE_CONTRACT_TRANSFER',
    },
  ];

  // 币币资金流水来源筛选
  const coinSourceOption = [
    {
      text: t('全部'),
      value: undefined,
    },
    {
      text: t('充值'),
      value: 'RECHARGE',
    },
    {
      text: t('提现'),
      value: 'WITHDRAW',
    },
    {
      text: t('现货交易'),
      value: 'TRADE_SPOT',
    },
    {
      text: t('定期理财交易'),
      value: 'TRADE_FIXED_TERM',
    },
    {
      text: t('申购IEO交易'),
      value: 'TRADE_IEO_SUBSCRIPTION',
    },
    {
      text: t('AI量化交易'),
      value: 'TRADE_AI_QUANT',
    },
    {
      text: t('邀请返佣'),
      value: 'INVITE_REBATE',
    },
    {
      text: t('分销返佣'),
      value: 'DISTRIBUTION_REBATE',
    },
    {
      text: t('贷款'),
      value: 'LOAN',
    },
    {
      text: t('体验金'),
      value: 'EXPERIENCE_FUND',
    },
  ]

  // 合约来源筛选
  const contractSourceFilter = [
    {
      text: t('购买'),
      value: [FundsOrderHistoryEnum.CONTRACT_MARKET_BUY, FundsOrderHistoryEnum.CONTRACT_LIMIT_BUY],
    },
    {
      text: t('结算'),
      value: [FundsOrderHistoryEnum.CONTRACT_CLOSING_SETTLEMENT, FundsOrderHistoryEnum.CONTR],
    },
  ];


  // text是展示的文字，value是根据后端映射值来的，type是区分账户类型，sourceClassification是来源分类

  const sourceOptions = [
    // {
    //   text: t('提现'),
    //   value: FundsOrderHistoryEnum.CONTRACT_WITHDRAWAL,
    //   type: WalletTypeEnum.CONTRACT,
    //   sourceClassification: FundsSourceEnum.ACCOUNT_OPERATION,
    // },
    {
      text: t('提现'),
      value: FundsOrderHistoryEnum.PAYOUTS,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.ACCOUNT_OPERATION,
    },
    {
      text: t('取消提现'),
      value: FundsOrderHistoryEnum.CANCEL_WITHDRAWAL,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.ACCOUNT_OPERATION,
    },
    {
      text: t('取消提现手续费'),
      value: FundsOrderHistoryEnum.CANCEL_WITHDRAWAL_FEE,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.ACCOUNT_OPERATION,
    },
    {
      text: t('划转'),
      value: FundsOrderHistoryEnum.CONTRACT_TRANSFER,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.ACCOUNT_OPERATION,
    },
    {
      text: t('贷款'),
      value: FundsOrderHistoryEnum.LOAN,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.ACCOUNT_OPERATION,
    },
    {
      text: t('还款'),
      value: FundsOrderHistoryEnum.REPAYMENT,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.ACCOUNT_OPERATION,
    },
    {
      text: t('提现失败'),
      value: FundsOrderHistoryEnum.CONTRACT_WITHDRAWAL_FAIL,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.ACCOUNT_OPERATION,
    },
    {
      text: t('银行卡充值'),
      value: FundsOrderHistoryEnum.RECHARGE_BANK_ACCOUNT,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.ACCOUNT_OPERATION,
    },
    {
      text: t('加密货币充值'),
      value: FundsOrderHistoryEnum.RECHARGE_CRYPTOCURRENCY,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.ACCOUNT_OPERATION,
    },
    {
      text: t('API自动充值'),
      value: FundsOrderHistoryEnum.RECHARGE_COIN_ACCOUNT,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.ACCOUNT_OPERATION,
    },
    {
      text: t('系统充值'),
      value: FundsOrderHistoryEnum.RECHARGE_MANAGER,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.ACCOUNT_OPERATION,
    },
    {
      text: t('闪兑'),
      value: FundsOrderHistoryEnum.ORDER_FLASH_EXCHANGE,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.ACCOUNT_OPERATION,
    },

    // {
    //   text: t('合约订单交易'),
    //   value: FundsOrderHistoryEnum.ORDER_CONTRACT,
    //   type: WalletTypeEnum.CONTRACT,
    //   sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    // },
    {
      text: t('内测赠送'),
      value: FundsOrderHistoryEnum.FREE_FOR_INTERNAL_TESTING,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    },
    {
      text: t('内测赠送资金退回'),
      value: FundsOrderHistoryEnum.INTERNAL_BETA_FREE_FUNDS_REFUND,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    },
    {
      text: t('AI交易'),
      value: FundsOrderHistoryEnum.AI_ORDER_TRADE,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.BINANCE_OPERATION,
    },
    {
      text: t('邀请返佣'),
      value: FundsOrderHistoryEnum.INVITATION_REBATE,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.BINANCE_OPERATION,
    },
    {
      text: t('邀请返现'),
      value: FundsOrderHistoryEnum.INVITATION_REBATE_CASH,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.BINANCE_OPERATION,
    },
    {
      text: t('分销返佣'),
      value: FundsOrderHistoryEnum.DISTRIBUTION_REBATE,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.BINANCE_OPERATION,
    },
    {
      text: t('分销返现'),
      value: FundsOrderHistoryEnum.DISTRIBUTION_REBATE_CASH,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.BINANCE_OPERATION,
    },
    // {
    //   text: t('平仓手续费'),
    //   value: FundsOrderHistoryEnum.CONTRACT_CLOSING_FEE,
    //   type: WalletTypeEnum.CONTRACT,
    //   sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    // },

    {
      text: t('赠送体验金'),
      value: FundsOrderHistoryEnum.GIVE_AWAY,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.BINANCE_OPERATION,
    },

    {
      text: t('归还体验金'),
      value: FundsOrderHistoryEnum.EXPERIENCE_FUND_REFUND,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.BINANCE_OPERATION,
    },

    {
      text: t('归还体验金利润'),
      value: FundsOrderHistoryEnum.EXPERIENCE_FUND_PROFIT_DEDUCTION,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.BINANCE_OPERATION,
    },
    {
      text: t('现货-市价'),
      value: FundsOrderHistoryEnum.CONTRACT_ORDER_TRADE,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.BINANCE_OPERATION,
    },
    {
      text: t('现货-限价'),
      value: FundsOrderHistoryEnum.CONTRACT_ORDER_TRADE_PRICE_LIMIT,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.BINANCE_OPERATION,
    },
    {
      text: t('理财服务费'),
      value: FundsOrderHistoryEnum.BANKING_FEES,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.BINANCE_OPERATION,
    },
    {
      text: t('定期理财交易'),
      value: FundsOrderHistoryEnum.REGULAR_FINANCIAL_MANAGEMENT,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.BINANCE_OPERATION,
    },
    {
      text: t('AI量化交易'),
      value: FundsOrderHistoryEnum.AI_QUANTITATIVE_TRADING,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.BINANCE_OPERATION,
    },
    {
      text: t('AI量化交易手续费'),
      value: FundsOrderHistoryEnum.TRADE_AI_QUANT,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.BINANCE_OPERATION,
    },
    {
      text: t('市价购买'),
      value: FundsOrderHistoryEnum.CONTRACT_MARKET_BUY,
      type: WalletTypeEnum.CONTRACT,
      sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    },
    {
      text: t('限价购买'),
      value: FundsOrderHistoryEnum.CONTRACT_LIMIT_BUY,
      type: WalletTypeEnum.CONTRACT,
      sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    },
    {
      text: t('取消委托'),
      value: FundsOrderHistoryEnum.CONTRACT_REVOKE,
      type: WalletTypeEnum.CONTRACT,
      sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    },
    {
      text: t('平仓结算'),
      value: FundsOrderHistoryEnum.CONTRACT_CLOSING_SETTLEMENT,
      type: WalletTypeEnum.CONTRACT,
      sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    },
    {
      text: t('平仓扣除'),
      value: FundsOrderHistoryEnum.CONTR,
      type: WalletTypeEnum.CONTRACT,
      sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    },
    {
      text: t('强平'),
      value: FundsOrderHistoryEnum.CONTRACT_FORCED_LIQUIDATION,
      type: WalletTypeEnum.CONTRACT,
      sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    },
    {
      text: t('逐仓强平'),
      value: FundsOrderHistoryEnum.CONTRACT_ISOLATED_FORCED_LIQUIDATION,
      type: WalletTypeEnum.CONTRACT,
      sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    },
    {
      text: t('限价'),
      value: FundsOrderHistoryEnum.CONTRACT_LIMIT,
      type: WalletTypeEnum.CONTRACT,
      sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    },
    {
      text: t('止盈止损'),
      value: FundsOrderHistoryEnum.CONTRACT_STOP_PROFIT_LOSS,
      type: WalletTypeEnum.CONTRACT,
      sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    },
    // {
    //   text: t('跟单订单完成'),
    //   value: FundsOrderHistoryEnum.CONTRACT_LEAD_TRADING,
    //   type: WalletTypeEnum.CONTRACT,
    //   sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    // },
    // {
    //   text: t('跟单订单平仓'),
    //   value: FundsOrderHistoryEnum.CONTRACT_LEAD_TRADING_COMPLETE,
    //   type: WalletTypeEnum.CONTRACT,
    //   sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    // },
    //***** x */
    {
      text: t('申购IEO购买'),
      value: FundsOrderHistoryEnum.SUBSCRIPTION_IEO_BUY,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.SUBSCRIPTION,
    },
    {
      text: t('申购IEO补缴'),
      value: FundsOrderHistoryEnum.SUBSCRIPTION_IEO_BUY_PAY_BACK,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.SUBSCRIPTION,
    },
    {
      text: t('申购IEO退款'),
      value: FundsOrderHistoryEnum.SUBSCRIPTION_IEO_REIMBURSE,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.SUBSCRIPTION,
    },
    {
      text: t('申购IEO结算'),
      value: FundsOrderHistoryEnum.SUBSCRIPTION_IEO_SETTLEMENT,
      type: WalletTypeEnum.COIN,
      sourceClassification: FundsSourceEnum.SUBSCRIPTION,
    },
    // 快速合约 市价购买
    {
      text: t('极速合约购买'),
      value: FundsOrderHistoryEnum.FAST_CONTRACT_PURCHASE,
      type: WalletTypeEnum.FASTCONTRACT,
      sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    },
    {
      text: t('极速合约结算'),
      value: FundsOrderHistoryEnum.FAST_CONTRACT_SETTLEMENT,
      type: WalletTypeEnum.FASTCONTRACT,
      sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    },

    {
      text: t('资金费率'),
      value: FundsOrderHistoryEnum.FUNDRATE,
      type: WalletTypeEnum.CONTRACT,
      sourceClassification: FundsSourceEnum.CONTRACT_OPERATION,
    },
  ];

  return {
    statusOption,
    directionOption,
    sourceOptions,
    screeningOfSourceOfCapitalOptions,
    contractSourceOption,
    coinSourceOption
  };
};
