import type { SVGProps } from 'react';

export function Shell(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="6"
        y="8"
        width="3"
        height="16"
        fill="currentColor"
      />
      <rect
        x="13"
        y="5"
        width="3"
        height="7"
        transform="rotate(90 13 5)"
        fill="currentColor"
      />
      <rect
        x="13"
        y="24"
        width="3"
        height="7"
        transform="rotate(90 13 24)"
        fill="currentColor"
      />
      <path d="M26.1422 14.5H15.1422V17.5H26.1422V14.5Z"  fill="hsl(var(--nextui-primary)" />
      <path
        d="M19.0711 23.1421L21.1924 21.0208L14.1213 13.9497L12 16.0711L19.0711 23.1421Z"
         fill="hsl(var(--nextui-primary)"
      />
      <path
        d="M21.1924 11.1213L19.0711 9L12 16.0711L14.1213 18.1924L21.1924 11.1213Z"
         fill="hsl(var(--nextui-primary)"
      />
    </svg>
  );
}
